@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 0%;

    --accent: 237 78.53% 65.29%;
    --accent-foreground: 191 74% 34%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: var(--border);

    --muted: var(--border);
    --muted-foreground: 215.4 16.3% 46.9%;

    --card: 0 0% 100%;
    --card-foreground: var(--foreground);

    --primary: 191 74% 34%;
    --primary-foreground: var(--background);

    --secondary: 238 79% 65%;
    --secondary-foreground: var(--background);

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: var(--accent-foreground);

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 7%;
    --foreground: 0 0% 100%;

    --accent: 237 78.53% 65.29%;
    --accent-foreground: 191 74% 34%;

    --popover: 0 0% 7%;
    --popover-foreground: 0 0% 100%;

    --border: 0 0% 18%;
    --input: var(--border);

    --muted: var(--input);
    --muted-foreground: 215.4 16.3% 56.9%;

    --card: 224 71% 4%;
    --card-foreground: var(--foreground);

    --primary: 191 74% 34%;
    --primary-foreground: var(--foreground);

    --secondary: 238 79% 65%;
    --secondary-foreground: var(--foreground);

    --destructive: 0 63% 31%;
    --destructive-foreground: 0 100% 50%;

    --ring: var(--accent-foreground);

    --radius: 0.5rem;
  }
}

@layer components {  
  .rounded-card {
    border-radius: 10px
  }

  .editable-border tr:is([data-tt-editable]):first-of-type,
  .editable-border div:is([data-tt-editable]):first-of-type
   {
    position: relative;
  }

  .editable-border  {
    position: relative;
  }

  .editable-border [contenteditable="true"] {
    outline: none;
  }

  .editable-border tr:is([data-tt-editable]):first-of-type::after,
  .editable-border div:is([data-tt-editable]):first-of-type::after {
    content: '';
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    border: 2px solid hsl(var(--muted-foreground));
    pointer-events: none; 
    transition: opacity 0.3s ease; 
  }

  .editable-border div:is([data-tt-editable]):first-of-type:hover::after,
  .editable-border tr:is([data-tt-editable]):first-of-type:hover::after {
    opacity: 1;
  }

  .editable-border div:is([data-tt-editable]):first-child:hover::before,
  .editable-border tr:is([data-tt-editable]):first-child:hover::before {
    opacity: 0.2;
  }

  .editable-border div:is([data-tt-editable]):first-of-type:focus-within::before,
  .editable-border tr:is([data-tt-editable]):first-of-type:focus-within::before {
    opacity: 0.2;
  }
  
  .editable-border div:is([data-tt-editable]):first-child::before,
  .editable-border tr:is([data-tt-editable]):first-child::before {
    content: 'Edit here';
    font-size: 0.75rem !important;
    font-family: Overpass !important;
    height: 19px !important;
    line-height: 24px! important;
    font-weight: 400 !important;
    padding-inline: 0.5rem;
    margin-right: 0.5rem;
    color: hsl(var(--background));
    opacity: 1;
    position: absolute;
    top: 0;
    margin-top: -17px;
    z-index: 99;
    right: 0;
    border-radius: 2px;
    background-color: hsl(var(--accent));
    pointer-events: none; 
    transition: opacity 0.3s ease; 
  }

  .placeholdereable[placeholder]:empty::before {
    content: attr(placeholder);
    color: hsl(var(--primary)); 
    opacity:0.5;
  }
  
  .placeholdereable[placeholder]:empty:focus::before {
        content: "";
  }

  .not-editable::before {
    content: 'Not editable';
    font-size: 0.75rem !important;
    font-family: Overpass !important;
    height: 19px !important;
    line-height: 24px! important;
    font-weight: 400 !important;
    font-size: 0.75rem;
    padding-inline: 0.5rem;
    margin-right: 0.1rem;
    color: hsl(var(--foreground));
    opacity: 0;
    position: absolute;
    top: 0;
    margin-top: -17px;
    z-index: 99;
    right: 0;
    border-radius: 2px;
    background-color: hsl(var(--muted));
    pointer-events: none; 
    transition: opacity 0.3s ease; 
  }

  .not-editable:hover::before {
    opacity:1;
  }

  .tiptap-menu-btn {
    all: unset;
    font-family: Sora;
    color: hsl(var(--foreground));
    @apply text-sm px-1 py-[2px] bg-transparent rounded-md border border-solid border-gray-400
  }

  .tiptap-menu-btn-active {
    all: unset;
    background-color: hsl(var(--foreground));
    color: hsl(var(--background));
    font-family: Sora;
    @apply text-sm px-1 py-[2px] rounded-md border border-solid border-gray-400
  }


  .tiptap-menu-grid {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
    grid-template-rows: auto;
    width: 320px;
  }
  
  .smat-p {
    min-height: 1rem;
  }
  .smat-h {
    min-height: 1.25rem;
    color: hsl(var(--primary)); 
  }
}

input, figure {
  all: unset;
}

p {
  margin: unset;
}

input[type=file] {
  display: none;
}

a:link {
  text-decoration: none;
  color: currentColor;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.cookieModal__btnWrapper {
  justify-self: center;
}

.cookieModal {
  align-items: center;
  background: rgb(255, 255, 255);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 10000000000;
  padding: 1rem;
  bottom: 0px;
}

.cookieModal__link {
  text-decoration: none;
  color: #178097;
}

@media only screen and (min-width: 1024px) {
  .cookieModal {
    justify-content: space-between;
    flex-direction: row;
  }

  .cookieModal p {
    font-size: 1rem;
  }
}

.gsv {
  bottom: 30px !important;
  position: fixed;
  right: 30px !important;
  z-index: 888 !important;
}

@media (max-width: 600px) {
  .gsv {
    bottom: 75px !important;
    position: fixed;
    right: 15px !important;
    z-index: 888 !important;
  }
}

.cardDevice {
  background-color: rgba(0, 0, 0, 0.05);
  padding-bottom: 12rem;
}

.cardDevice__container {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  margin-left: 6rem;
  background-color: white;
}

.cardDevice__container__title {
  margin-bottom: 2rem;
  width: 550px;
}

.cardDevice__container__title h1 {
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.cardDevice__container__pillars {
  width: 100%;
  height: auto;
  margin-bottom: 4rem;
}

.cardDevice__container__pillar__container {
  text-align: center;
  justify-self: center;
  margin-bottom: 1rem;
  padding: 0 3rem;
}

.cardDevice__container__pillar__container img {
  padding: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardDevice__container__textWrapper {
  width: 20rem;
  padding: 0 0.8rem;
  font-size: 1.125rem;
  line-height: 1.3rem;
  margin-left: auto;
  margin-right: auto;
}

.cardDevice__container__imgWrapper img {
  height: 360px;
  width: auto;
  margin-bottom: 2rem;
}

.cardDevice__container__line {
  width: 100%;
  border-top-color: #017892;
}

@media screen and (max-width: 1340px) {
  .cardDevice__container {
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    margin: 0;
    background-color: white;
  }

  .cardDevice__container__title {
    margin-bottom: 3rem;
    text-align: center;
    width: 100%;
  }

  .cardDevice__container__title h1 {
    margin-bottom: 2rem;
    color: #017892;
  }

  .cardDevice__container__pillars {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  .cardDevice__container__pillar__container {
    padding: 0 1.5rem;
  }

  .cardDevice__container__imgWrapper {
    height: auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .cardDevice__container__imgWrapper img {
    padding: 0;
    height: auto;
    width: 100%;
  }

  .cardDevice__container__textWrapper {
    margin-bottom: 4rem;
    font-size: 1rem;
  }

  .cardDevice__container__line {
    width: 0;
    border: 0;
  }
}

.PhoneInput {
  width: 100% !important;
}

input.PhoneInputInput {
  font-size: 1rem !important;
  width: 100% !important;
  font-family: var(--font-overpass) !important;
  background-color: transparent;
}

.underlineLink {
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.underlineLink:hover {
  font-weight: bold;
}

.underlineLink::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: #6166EC;
  transition: opacity 400ms, transform 400ms;
  opacity: 1;
  transform: translate3d(-102%, 0, 0);
}
.underlineLink:hover::after,
.underlineLink:focus::after {
  transform: translate3d(0, 0, 0);
}

input[type='file']::file-selector-button {
  display: none;
}

input[type='file']::-webkit-file-upload-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: -100%;
}

input[type='file']::-ms-browse {
  display: none;
}

.MuiDataGrid-root {
  border: 1px solid #272d3b33 !important;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 
  0px 3px 4px 0px rgba(0,0,0,0.14),
  0px 1px 8px 0px rgba(0,0,0,0.12) !important;
  font-family: #178097 !important;
  font-size: 13px !important;
  border-radius: 10px !important;
  min-height: 322px !important;
  max-width: 1536px !important;
}
.MuiDataGrid-renderingZone {
  width: 100% !important;
}

.MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.MuiDataGrid-root .MuiDataGrid-window::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

.MuiDataGrid-root .MuiDataGrid-footer {
  justify-content: center !important;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-viewport,
.MuiDataGrid-root .MuiDataGrid-cell--textCenter.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-root .MuiDataGrid-cell--textCenter.MuiDataGrid-cell--editing {
  overflow: visible !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0 10px !important;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  min-width: 100% !important;
  width: fit-content !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader {
  display: flex !important;
  position: relative !important;
  align-items: center !important;
  flex-grow: 1;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderWrapper {
  justify-content: space-between !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  color: #6e6e6e;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell,
.MuiDataGrid-root .MuiDataGrid-cellCenter {
  overflow: hidden !important;
  white-space: nowrap !important;
  border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
  text-overflow: ellipsis !important;
  color: #6e6e6e;
  font-weight: 500;
  outline: none !important;
}

.MuiDataGrid-columnHeader .MuiDataGrid-columnHeader--sortable {
  flex-grow: 1;
  max-width: none;
  width: unset;
}

.MuiDataGrid-root .MuiDataGrid-cellCenter {
  overflow: visible !important;
}

.lastColumn {
  position: relative;
}

/* Basic editor styles */
 .tiptap > * + * {
	 margin-top: 0.75em;
}
 .tiptap ul, .tiptap ol {
	 padding: 0 1rem;
}
 .tiptap h1, .tiptap h2, .tiptap h3, .tiptap h4, .tiptap h5, .tiptap h6 {
	 line-height: 1.1;
}
 .tiptap code {
	 background-color: rgba(97, 97, 97, 0.1);
	 border-radius: 0.25em;
	 box-decoration-break: clone;
	 color: #616161;
	 font-size: 0.9rem;
	 padding: 0.25em;
}
 .tiptap pre {
	 background: #0d0d0d;
	 color: #fff;
	 font-family: 'JetBrainsMono', monospace;
	 padding: 0.75rem 1rem;
	 border-radius: 0.5rem;
}
 .tiptap pre code {
	 color: inherit;
	 padding: 0;
	 background: none;
	 font-size: 0.8rem;
}
 .tiptap img {
	 max-width: 100%;
	 height: auto;
}
 .tiptap blockquote {
	 padding-left: 1rem;
	 border-left: 2px solid rgba(13, 13, 13, 0.1);
}
 .tiptap hr {
	 border: none;
	 border-top: 2px solid rgba(13, 13, 13, 0.1);
	 margin: 2rem 0;
}
 a {
	 color: #68cef8;
}
 .ProseMirror {
	 min-height: 300px;
	 border-radius: 10px;
	 outline: none;
	 padding: 1em;
   color: #0d0d0d;
   font-weight: 400;
   font-family: Overpass;
   font-size: 1rem;
}
 .items {
	 position: relative;
	 border-radius: 0.25rem;
	 overflow: hidden;
	 font-size: 0.9rem;
	 box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
 .item {
	 display: block;
	 width: 100%;
	 text-align: left;
	 /* background: transparent; */
	 border: none;
	 padding: 0.2rem 0.5rem;
}
 .item.is-selected, .item:hover {
	 color: white;
	 background: hsla(var(--accent)/85%);
}

@media (prefers-color-scheme: dark) {
  .items {
      background: white;
      color: rgba(0, 0, 0, 0.8);
  }
}

@media (prefers-color-scheme: light) {
  .items {
      background: black;
      color: rgba(255, 255, 255, 0.8);
  }
}

.bubble-menu {
  background-color: red !important;
  border: 1px solid var(--gray-1);
  border-radius: 0.7rem;
  box-shadow: var(--shadow);
  display: flex;
  padding: 0.2rem;

  button {
    background-color: unset;

    &:hover {
      background-color: var(--gray-3);
    }

    &.is-active {
      background-color: var(--purple);

      &:hover {
        background-color: var(--purple-contrast);
      }
    }
  }
}

 blockquote {
	 border-left: 3px solid rgba(13, 13, 13, 0.1);
	 padding-left: 1rem;
}
 
.disable-cursor {
  pointer-events: none;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap p.is-empty::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
